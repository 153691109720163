const PLAY_STORE_PROD_TAG = 'com.guesthouser.hub';
const APP_STORE_PROD_ID = 'id1523219759';
const IMAGEKIT_URI = 'https://ik.imagekit.io/32ndhub/';
const TYPEFORM_PRODUCT_KEY_PROD = 'BdMNFExQ';
const TYPEFORM_INVEST_KEY_PROD = 'RJNGK2aE';
const TYPEFORM_PRODUCT_KEY_STAGING = 'NTkABo0o';
const TYPEFORM_INVEST_KEY_STAGING = 'fskNsVKg';
const MIXPANEL_TOKEN_PRODUCTION = '7393ba95e5dd861245afe5dc4eb90745';
const MIXPANEL_TOKEN_STAGING = '5fbbb80a12d0824dbba1710aa6b0dbc4';
const IP_GEO_LOCATION_KEY = '27ef24d024b5470c80749bf023bca488';
const WHATSAPP_COMMUNIITY = 'https://chat.whatsapp.com/GI0pvgNm6QD2Vea8XDtrUc';
const HYPERVERGE_APP_ID_STAGING = 'iamys3';
const HYPERVERGE_APP_ID_PRODUCTION = 'bd9uad';

export enum Environments {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEV = 'dev',
  QA = 'qa',
  DEFAULT = 'default',
}

interface AppConstantsType {
  API_URL: string;
  WS_API_URL: string;
  IMAGEKIT_PROD_URI: string;
  WEB_URL: string;
  PLAY_STORE_PROD_ID: string;
  APP_STORE_ID: string;
  SCHEME: string;
  APP_ENV: string;
  TYPEFORM_PRODUCT: string;
  TYPEFORM_INVEST: string;
  MIXPANEL_TOKEN: string;
  REST_URL: string;
  IP_GEO_LOCATION_KEY: string;
  WHATSAPP_COMMUNIITY: string;
  HYPERVERGE_APP_ID: string;
}

const EnvironmentConfig: Record<Environments, AppConstantsType> = {
  production: {
    API_URL: 'https://api.club.32nd.com/graphql',
    WS_API_URL: 'wss://api.club.32nd.com/graphql',
    IMAGEKIT_PROD_URI: IMAGEKIT_URI,
    WEB_URL: 'https://club.32nd.com',
    PLAY_STORE_PROD_ID: PLAY_STORE_PROD_TAG,
    APP_STORE_ID: APP_STORE_PROD_ID,
    SCHEME: 'club.32nd',
    APP_ENV: 'production',
    TYPEFORM_PRODUCT: TYPEFORM_PRODUCT_KEY_PROD,
    TYPEFORM_INVEST: TYPEFORM_INVEST_KEY_PROD,
    MIXPANEL_TOKEN: MIXPANEL_TOKEN_PRODUCTION,
    REST_URL: 'https://api.club.32nd.com/rest',
    IP_GEO_LOCATION_KEY,
    WHATSAPP_COMMUNIITY,
    HYPERVERGE_APP_ID: HYPERVERGE_APP_ID_PRODUCTION,
  },
  staging: {
    API_URL: 'https://backend.stg.32nd.com/graphql',
    WS_API_URL: 'wss://backend.stg.32nd.com/graphql',
    IMAGEKIT_PROD_URI: IMAGEKIT_URI,
    WEB_URL: 'https://frontend.stg.32nd.com',
    PLAY_STORE_PROD_ID: PLAY_STORE_PROD_TAG,
    APP_STORE_ID: APP_STORE_PROD_ID,
    SCHEME: 'staging.club.32nd',
    APP_ENV: 'staging',
    TYPEFORM_PRODUCT: TYPEFORM_PRODUCT_KEY_STAGING,
    TYPEFORM_INVEST: TYPEFORM_INVEST_KEY_STAGING,
    MIXPANEL_TOKEN: MIXPANEL_TOKEN_STAGING,
    REST_URL: 'https://backend.stg.32nd.com/rest',
    IP_GEO_LOCATION_KEY,
    WHATSAPP_COMMUNIITY,
    HYPERVERGE_APP_ID: HYPERVERGE_APP_ID_STAGING,
  },
  dev: {
    API_URL: 'https://dev-backend.stg.32nd.com/graphql',
    WS_API_URL: 'wss://dev-backend.stg.32nd.com/graphql',
    IMAGEKIT_PROD_URI: IMAGEKIT_URI,
    WEB_URL: 'https://dev.club.32nd.com',
    PLAY_STORE_PROD_ID: PLAY_STORE_PROD_TAG,
    APP_STORE_ID: APP_STORE_PROD_ID,
    SCHEME: 'staging.club.32nd',
    APP_ENV: 'dev',
    TYPEFORM_PRODUCT: TYPEFORM_PRODUCT_KEY_STAGING,
    TYPEFORM_INVEST: TYPEFORM_INVEST_KEY_STAGING,
    MIXPANEL_TOKEN: MIXPANEL_TOKEN_STAGING,
    REST_URL: 'https://dev-backend.stg.32nd.com/rest',
    IP_GEO_LOCATION_KEY,
    WHATSAPP_COMMUNIITY,
    HYPERVERGE_APP_ID: HYPERVERGE_APP_ID_STAGING,
  },
  qa: {
    API_URL: 'https://qa.api.club.32nd.com/graphql',
    WS_API_URL: 'wss://qa.api.club.32nd.com/graphql',
    IMAGEKIT_PROD_URI: IMAGEKIT_URI,
    WEB_URL: 'https://qa.club.32nd.com',
    PLAY_STORE_PROD_ID: PLAY_STORE_PROD_TAG,
    APP_STORE_ID: APP_STORE_PROD_ID,
    SCHEME: 'staging.club.32nd',
    APP_ENV: 'qa',
    TYPEFORM_PRODUCT: TYPEFORM_PRODUCT_KEY_STAGING,
    TYPEFORM_INVEST: TYPEFORM_INVEST_KEY_STAGING,
    MIXPANEL_TOKEN: MIXPANEL_TOKEN_STAGING,
    REST_URL: 'https://qa.api.club.32nd.com/rest',
    IP_GEO_LOCATION_KEY,
    WHATSAPP_COMMUNIITY,
    HYPERVERGE_APP_ID: HYPERVERGE_APP_ID_STAGING,
  },
  default: {
    API_URL: process.env.EXPO_PUBLIC_API_URL || 'http://localhost:8000/graphql',
    WS_API_URL:
      process.env.EXPO_PUBLIC_WS_API_URL || 'ws://localhost:8000/graphql',
    IMAGEKIT_PROD_URI:
      process.env.EXPO_PUBLIC_IMAGEKIT_PROD_URI || IMAGEKIT_URI,
    WEB_URL: process.env.EXPO_PUBLIC_WEB_URL || 'localhost:19006',
    PLAY_STORE_PROD_ID: PLAY_STORE_PROD_TAG,
    APP_STORE_ID: APP_STORE_PROD_ID,
    SCHEME: 'staging.club.32nd',
    APP_ENV: 'staging',
    TYPEFORM_PRODUCT: TYPEFORM_PRODUCT_KEY_STAGING,
    TYPEFORM_INVEST: TYPEFORM_INVEST_KEY_STAGING,
    MIXPANEL_TOKEN: MIXPANEL_TOKEN_STAGING,
    REST_URL: 'http://localhost:8000/rest',
    IP_GEO_LOCATION_KEY,
    WHATSAPP_COMMUNIITY,
    HYPERVERGE_APP_ID: HYPERVERGE_APP_ID_STAGING,
  },
};

const AppConstants: AppConstantsType =
  EnvironmentConfig[process.env.EXPO_PUBLIC_APP_ENV || 'default'];

export default AppConstants;
