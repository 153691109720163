/* eslint-disable arrow-body-style */
import { Environment, RecordSource, Store, Observable } from 'relay-runtime';
import { createClient } from 'graphql-ws';
import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  cacheMiddleware,
} from 'react-relay-network-modern';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import refreshTokenAPI from '../utilities/refreshToken';
import useAuthStore from '../stores/authStore';
import useUserStore from '../stores/userStore';
import AppConstants from '../utilities/AppConstants';
import { isValueNullOrEmpty } from '../utilities/Utility';
import OTAConfig from '../../react-native.config';

const { API_URL, WS_API_URL } = AppConstants;

const wsClient = createClient({
  url: WS_API_URL,
});

export interface RefreshTokenPayload {
  _id: string;
  token: string;
  refreshToken: string;
  activeRole?: string;
}

const subscribe = (operation, variables) => {
  return Observable.create((sink) => {
    return wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    );
  });
};

const { version } = Constants.expoConfig;
const modifiedAppVersion =
  !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
    ? `${version}-${OTAConfig.ota}`
    : version;

const middlewares = [
  urlMiddleware({
    url: () => API_URL,
    headers: () => {
      const headers: Record<string, any> = {
        platform: Platform.OS,
      };
      if (Platform.OS !== 'web') {
        headers.appversion = modifiedAppVersion;
      }
      return headers;
    },
  }),
  cacheMiddleware({
    size: 250, // max 100 requests
    ttl: 300000, // 5 minutes
    allowMutations: false,
    clearOnMutation: true,
  }),
  authMiddleware({
    /* eslint-disable no-return-await */
    token: async () => useAuthStore.getState().authToken,
    allowEmptyToken: true,
    /* eslint-disable consistent-return */
    tokenRefreshPromise: async () => {
      try {
        const data: RefreshTokenPayload = {
          _id: useUserStore.getState().id || null,
          token: useAuthStore.getState().refreshToken,
        };
        const tokenDetails = await refreshTokenAPI(data);

        if (tokenDetails && tokenDetails.getRefreshToken) {
          const { token, refreshToken } = tokenDetails.getRefreshToken;
          // set new access token and refresh token in loacal storage
          useAuthStore.getState().setLoginDetails(true, token, refreshToken);
          return token;
        }
      } catch (err) {
        useAuthStore.getState().setLoginDetails(false, '', '');
        useUserStore.getState().emptyUserDetails();
        return '';
      }
    },
  }),
];

const network = new RelayNetworkLayer(middlewares, { subscribeFn: subscribe });

const environment = new Environment({
  network,
  store: new Store(new RecordSource(), {
    queryCacheExpirationTime: 1000 * 60 * 30, // 5 Minutes
  }),
});

export default environment;
