import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import View from '../../basic/View/View';
import Radio from '../../primitive/Radio/Radio';
import Text from '../../primitive/Typography/Text/Text';
import Button from '../../primitive/Button/Button';
import { Capitalise } from '../../../../utilities/helper';

const RadioGroup = ({
  size,
  icon,
  radio,
  setRadio,
  collapsedCount,
  title,
  isRequired,
  type,
  ...rest
}) => {
  // Remove default selected values if item is not available or sold out.
  radio.map((item) => {
    if ((item.labelWarning || item.state === 'disabled') && item.checked) {
      item.checked = false;
    }
    return item;
  });

  if (radio.filter((item) => item.checked).length > 1) {
    const checkedIndex = radio.findIndex((item) => item.checked);
    radio.forEach((element, index) => {
      element.checked = false;
      if (checkedIndex === index) {
        element.checked = true;
      }
    });
  }

  const disabled = radio.filter(
    (item) => item.labelWarning || item.state === 'disabled',
  );

  radio = radio.filter(
    (item) => !(item.labelWarning || item.state === 'disabled'),
  );

  disabled.map((item) => radio.push(item));

  const toggleRadio = (id: React.Key, index: React.Key) => {
    const radioData = [...radio];

    radioData.forEach((element) => {
      element.checked = false;
    });

    radioData[index].checked = true;

    setRadio(radioData);
  };
  // console.log(collapsedCount);
  const [collapsedCnt, setCollapsedCnt] = useState(collapsedCount);
  // console.log(collapsedCount, collapsedCnt);

  useEffect(() => {
    setCollapsedCnt(collapsedCount);
  }, [collapsedCount]);

  useEffect(() => {}, [radio]);
  return (
    <View>
      {title && (
        <View
          mb="lg"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <View flex={1}>
            <Text
              size={rest?.titleSize ?? '2xl'}
              weight="medium"
              numberOfLines={2}
              pr="2xl"
            >
              {title}
            </Text>
          </View>
        </View>
      )}
      {radio
        .sort((a, b) => {
          return Number(a.label) - Number(b.label);
        })
        .slice(0, collapsedCnt || radio.length)
        .map(
          (
            item: {
              state: any;
              checked: any;
              id: React.Key;
              label: any;
              labelWarning: any;
              name: any;
            },
            index: React.Key,
          ) => (
            <View key={item.id || index} mb="lg">
              <Radio
                size={size}
                icon={icon}
                checked={item.checked ? item.checked : false}
                onPress={() => toggleRadio(item.id, index)}
                label={Number(item.label) > 0 ? item.label : ''}
                labelWarning={item.labelWarning ? item.labelWarning : ''}
                state={item.state ? item.state : 'active'}
                showAddSymbol={type !== 'variation'}
              >
                {Capitalise(item.name)}
              </Radio>
            </View>
          ),
        )}
      <View alignItems="flex-start">
        {collapsedCnt && collapsedCnt < radio.length && (
          <Button
            size="lg"
            status="info"
            appearance="ghost"
            iconRight="expand-outline-300"
            onPress={() => setCollapsedCnt(radio.length)}
          >
            +{radio.length - collapsedCnt}{' '}
            {radio.length - collapsedCnt === 1 ? 'Option' : 'Options'}
          </Button>
        )}
      </View>
    </View>
  );
};
RadioGroup.propTypes = {
  isRequired: PropTypes.number,
  collapsedCount: PropTypes.number,
  radio: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.string,
  icon: PropTypes.string,
};

RadioGroup.defaultProps = {
  isRequired: 0,
  collapsedCount: 0,
  radio: [],
  size: 'lg',
  icon: 'right',
};

export default RadioGroup;
